import React from 'react';

function MLB_RBILeadersTable() {
    return (
        <div className="bg-gray-100 p-6">
            <h1 className="text-2xl font-bold mb-4">Top 100 MLB RBI Leaders</h1>
            <div className="overflow-x-auto">
                <table className="table-auto w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-200">
                            <th className="border border-gray-300 px-4 py-2">Rank</th>
                            <th className="border border-gray-300 px-4 py-2">Player</th>
                            <th className="border border-gray-300 px-4 py-2">Team</th>
                            <th className="border border-gray-300 px-4 py-2">RBI</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border border-gray-300 px-4 py-2">1</td>
                            <td className="border border-gray-300 px-4 py-2">Mike Trout</td>
                            <td className="border border-gray-300 px-4 py-2">Los Angeles Angels</td>
                            <td className="border border-gray-300 px-4 py-2">120</td>
                        </tr>
                        <tr>
                            <td className="border border-gray-300 px-4 py-2">2</td>
                            <td className="border border-gray-300 px-4 py-2">Aaron Judge</td>
                            <td className="border border-gray-300 px-4 py-2">New York Yankees</td>
                            <td className="border border-gray-300 px-4 py-2">115</td>
                        </tr>
                        {/* You can continue adding more rows here */}
                        {/* Replace this with actual data for the top 100 MLB RBI leaders */}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default MLB_RBILeadersTable;
